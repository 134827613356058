<template>
  <b-col cols="12" class="mt-48 mt-sm-96">
    <p class="hp-p1-body text-center hp-text-color-black-60 mb-8">
      Copyright 2023 摩卡科技.
    </p>

    <b-row
      align-h="center"
      align-v="center"
      style="margin-left: -8px; margin-right: -8px"
    >
      <b-col class="w-auto hp-flex-none px-8">
        <a href="https://t.me/gxfc8816" target="_blank" rel="noopener noreferrer" class="hp-text-color-black-80 hp-text-color-dark-40">在线客服</a>
      </b-col>

      <b-col class="w-auto hp-flex-none px-8">
        <a href="https://t.me/gxfc8816" target="_blank" rel="noopener noreferrer" class="hp-text-color-black-80 hp-text-color-dark-40">申请代理</a>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { BRow, BCol, BLink } from "bootstrap-vue";

import Logo from "@/layouts/components/logo";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    Logo,
  },
};
</script>
